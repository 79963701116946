export const releaseNotesDe: ReleaseNotes = [
    {
        release: "1.1.0",
        date: "2024-10-09",
        tags: [
            "Status", "Export", "Optimierungen"
        ],
        type: "minor",
        notes: [
            {
                "description": "Neuer Mangelpunkt Status: 'besichtigt'"
            },
            {
                "description": "Fehlerbehebung beim Export von allen Mangelpunkten in einem Bericht"
            },
            {
                "description": "Fehlerbehebung und UI Optimierungen"
            },
        ]
    },
    {
        release: "1.0.0",
        date: "2024-07-29",
        tags: [
            "Bericht", "Sortierung", "Optimierungen"
        ],
        type: "major",
        notes: [
            {
                "description": "Neue Felder für das Objekt in den Berichten"
            },
            {
                "description": "Neue Felder für den vorliegenden Bericht in den Berichten"
            },
            {
                "description": "Optimierung in der Darstellung beim Anlegen von neuen Datensätzen"
            },
            {
                "description": "Fehlerbehebung bei der Sortierung von Mangelpunkten nach der Nummer"
            }
        ]
    },
    {
        release: "0.8.0",
        date: "2024-07-22",
        tags: [
            "Export", "Optimierungen"
        ],
        type: "minor",
        notes: [
            {
                "description": "Der Bug bei deim in IOS keine neuen Kommentare erstellt werden konnten wurde behoben"
            },
            {
                "description": "Der Speichern Button wird nur noch bei ungespeicherten Änderungen angezeigt"
            },
            {
                "description": "Bei dem Export von Mangelpunkten kann ein optional ein Kommentar mitgegeben werden"
            },
            {
                "description": "Allgemeine Optimierungen und Fehlerbehebungen"
            }
        ]
    },
    {
        release: "0.7.0",
        date: "2024-07-12",
        tags: [
            "Export"
        ],
        type: "minor",
        notes: [
            {
                "description": "Der Bericht PDF Export läuft jetzt über die Mangelpunkte Tabelle"
            },
            {
                "description": "In dem Bericht Export werden jetzt auch die Bilder der Mangelpunkte angezeigt"
            },
            {
                "description": "Es kann jetzt ausgewählt werden welche Mangelpunkt exportiert werden sollen"
            }
        ]
    },
    {
        release: "0.6.0",
        date: "2024-07-10",
        tags: [
            "Historie", "Wilkommens-Mail", "Bilder-Upload", "Filter", "Optimierungen"
        ],
        type: "minor",
        notes: [
            {
                "description": "Beim Anlegen eines neuen Benutzers wird eine Willkommens-E-Mail zum Setzten des Passworts versendet"
            },
            {
                "description": "Historie Einträge aus den Mangelpunkten werden auch in der Historie des Berichts angezeigt"
            },
            {
                "description": "Fehlerbehebung und Optimierungen beim Bilder Upload"
            },
            {
                "description": "Neue Filter bei den Mangelpunkten"
            },
            {
                "description": "Allgemeine Optimierungen und Fehlerbehebungen"
            }
        ]
    },
    {
        release: "0.5.0",
        date: "2024-06-14",
        tags: [
            "Historie", "Optimierungen"
        ],
        type: "minor",
        notes: [
            {
                "description": "Änderungen bei Kommentatren, Bildern und Status werden jetzt in der Mangelpunkt Historie festgehalten"
            },
            {
                "description": "UI Optimierungen"
            }
        ]
    },
    {
        release: "0.4.0",
        date: "2024-06-13",
        tags: [
            "Kommentare", "Bilder", "Export", "PHP8.3"
        ],
        type: "minor",
        notes: [
            {
                "description": "Optimierungen beim erstellen und bearbeiten von Mangelpunkten"
            },
            {
                "description": "Es können jetzt Kommentare in Mangelpunkten verfasst werden"
            },
            {
                "description": "Es können jetzt Bilder in Mangelpunkten hochgeladen werden"
            },
            {
                "description": "Das Passwort eines Benutzers kann nun in den Benutzereinstellungen geändert werden"
            },
            {
                "description": "Berichte können jetzt als PDF exportiert werden"
            },
            {
                "description": "Update auf PHP 8.3"
            }
        ]
    },
    {
        release: "0.3.0",
        date: "2024-05-31",
        tags: [
            "Berichte", "Mangelpunkte", "Status"
        ],
        type: "minor",
        notes: [
            {
                "description": "Optimierungen beim erstellen und bearbeiten von Berichten"
            },
            {
                "description": "Mangelpunkte können nun erstellt und bearbeitet werden"
            },
            {
                "description": "Mangelpunkte können nun einem Status zugewiesen werden"
            }
        ]
    },
    {
        release: "0.2.0",
        date: "2024-05-22",
        tags: [
            "Berichte"
        ],
        type: "minor",
        notes: [
            {
                "description": "Berichte können nun erstellt und bearbeitet werden"
            }
        ]
    },
    {
        release: "0.1.0",
        date: "2024-05-16",
        tags: [
            "Grundsystem"
        ],
        type: "minor",
        notes: [
            {
                "description": "Erste Fertigstellung des Grundsystems"
            }
        ]
    },
    {
        release: "0.0.0",
        date: "2024-03-26",
        tags: [
            "Projektstart"
        ],
        type: "major",
        notes: [
            {
                "description": "Das Projekt wurde aufgesetzt. Die Entwicklung kann beginnen!"
            }
        ]
    }
]